import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import invoke from 'react-native-webview-invoke/browser'
import '../../Custom-css.css';
import {Container, Button} from 'react-bootstrap'

import history from './../history';
import { IoArrowBack } from "react-icons/io5";
import { CircularProgressbar } from 'react-circular-progressbar';
import {ucWords, formatAngka} from './../custom-func';
import Swal from 'sweetalert2';

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})



const {
    REACT_APP_API_URL,
} = process.env;

class DetailProduksi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            keywords:'',
            transaksi: [],
            trx_detail: [],
            estimasiSelesai:'',
            color: '',
            notif: '',
            selisih: '',
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.detailProduksi = this.detailProduksi.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.pilihPekerjaan = this.pilihPekerjaan.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            let ke = this.query.get('ke');  
            if(ke != 'masuk_rak'){
                
                const level = localStorage.getItem('level');
                const role = JSON.parse(localStorage.getItem('role'));
                if(level < 3 || role.pkj != 1 ){
                    swalKC.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Maaf anda tidak memiliki akses di fitur ini',
                        showCancelButton: false,
                        confirmButtonText: 'Kembali'
                        }).then((result) => {
                            history.goBack()
                        })
                }
            }


            this.detailProduksi()
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    detailProduksi(){
        let kdTrx = this.query.get('kdTrx');
        this.setState({ loading: 1 })

        fetch(REACT_APP_API_URL+'get-detail-produksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                kode_transaksi: kdTrx,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            this.setState({
                loading: 0,
                transaksi: responseJson.data.transaksi,
                trx_detail: responseJson.data.trx_detail,
                estimasiSelesai: responseJson.data.estimasiSelesai,
                color: responseJson.data.color,
                notif: responseJson.data.notif,
                selisih: responseJson.data.selisih
            })
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }

    onImageChange(event) {
        const target = event.target;
        const name = target.name;
        console.log(name)
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            // swalKC.fire({
            //     text: 'Simpan foto ini?',
            //     imageUrl: URL.createObjectURL(img),
            //     imageWidth: '100%',
            //     imageHeight: '500px',
            //     imageAlt: 'Custom image',
            //     showCancelButton: true,
            //     cancelButtonText: 'Batal',
            //     confirmButtonText: 'Ya, Simpan!'
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //         this.uploadImage(name,img)
            //     }
            //   })
              this.uploadImage(name,img)

        }
    }

    uploadImage(name,img){

        this.setState({disabled:true})
        let formData = new FormData();
        formData.append("kd_detail_tx", name);
        formData.append("img", img);
        formData.append("kode_user", localStorage.getItem('kode_user'));
        formData.append("api_token", localStorage.getItem('token'));
        formData.append("schema", localStorage.getItem('schema'));

        fetch(REACT_APP_API_URL+'transaksi-upload-image', {method: "POST", body: formData})
        .then((responseJson) => {
            console.log(responseJson)
            this.detailProduksi()
            this.setState({disabled:false})
        })
        .catch((error) => {
            console.error(error);
            this.detailProduksi()
            this.setState({disabled:false})
        });
    }


    pilihPekerjaan(detTrx, kode_transaksi, kode_layanan, status_pengerjaan){
        if(status_pengerjaan < 9){
            let ke = this.query.get('ke');
            this.props.history.push('proses-produksi?ke='+ke+'&detTrx='+detTrx+'&kode_transaksi='+kode_transaksi+'&kode_layanan='+kode_layanan)
        }

    }

    
    render() {  
        let ts = this;
        const pilihFile = invoke.bind('pilihFile')
        const uploadFile = async function(detTrx) {
            var url = REACT_APP_API_URL+'transaksi-upload-image';
            var kode_user = localStorage.getItem('kode_user');
            var token = localStorage.getItem('token');
            var schema = localStorage.getItem('schema');
            await pilihFile(url, detTrx, kode_user, token, schema)
        }

        invoke.define('statusGambar', statusGambar)
        function statusGambar(status) {
            ts.detailProduksi()
        }


        const detailItemTx = this.state.trx_detail.map((val, id) => {
            return (
                <div key={val.kode_detail_tx} className="border mb-3 mt-3">
                    <div className="w-100 border-bottom" onClick={() => this.pilihPekerjaan(val.kode_detail_tx, val.kode_transaksi, val.kode_layanan, val.status_pengerjaan)}>
                        <div className="p-1 pl-2">
                            <div className="font-weight-bold" >{ucWords(val.nama_item)}</div>

                            {
                                val.total_luas > 0 ?
                                    <div className="text-grey">{formatAngka(val.panjang_item) * 1} x {formatAngka(val.lebar_item) * 1} {val.nama_satuan} = {formatAngka(val.total_luas) * 1} {val.nama_satuan}</div>
                                    : null
                            }
                                    
                            <div className="row"> 
                                <div className="col-8">
                                    {
                                        val.total_luas > 0 ?
                                            <div className="text-grey">{formatAngka(val.total_luas) * 1} {val.nama_satuan} x {formatAngka(val.total_item) * 1}</div>
                                            :
                                            <div className="text-grey">{formatAngka(val.total_item)} {val.nama_satuan}</div>
                                    }
                                </div>

                            </div>
                            
                            <div className="font-10 text-grey mt-2">Catatan : {val.notes ? val.notes : '-'}</div>
                            <div><span className="badge bg-kc text-white">{val.nama_pekerjaan}</span></div>

                            {
                                val.jenis_pakaian != null ?
                                    JSON.parse(val.jenis_pakaian).map((v, i) => {
                                        return(
                                        <span key={v.kode_pakaian} className="font-10 text-grey">
                                            {' '} {ucWords(v.nama_pakaian)} : {v.jumlah_pakaian} PCS | 
                                        </span>
                                    )})
                                : null
                            }

                        </div>
                    </div>

                    <div className="border-bottom w-100">
                        <div style={{overflowX:'auto', display:'flex'}}>
                        {   
                            val.gambar != null ?
                            JSON.parse(val.gambar).map((v, i) => {
                                return(
                                    <div style={{flex: '0 0 25%', height:90}}  key={i} className="p-1" >
                                        <img src={"https://kc-imgs.oss-ap-southeast-5.aliyuncs.com/kc/transaksi/"+localStorage.getItem('schema')+"/"+v+"?x-oss-process=image/resize,m_fixed,w_90,h_70"} />
                                    </div>
                                )
                            })
                            : null
                        }
                        </div>
                    </div>
{/* 
                    <input type="file" name={val.kode_detail_tx} id={'file'+id} onChange={this.onImageChange} className="inputfile" />
                    <label for={'file'+id}>Upload Gambar Produksi</label>
                    <div className="p-2">
                        <Button className="btn-light w-100 btn-kc color-kc text-white br-0" block onClick={ () => uploadFile(val.kode_detail_tx) } > <h6 className="m-0 p-0">Upload Foto</h6> </Button>
                    </div>
                     */}

                    {
                        JSON.parse(val.history).map((vh, ih) => {
                            return (
                                <div key={ih} className="border-top p-2">
                                    <div className="row">
                                        <div className="col-4" style={{borderRight:'3px solid #008000'}}>
                                            <div style={{ fontSize: 25, fontWeight: 'bold', color: '#008000' }}>{vh.jam_kerja}</div>
                                            <div style={{ fontSize: 12 }}>{vh.tgl_kerja}</div>
                                        </div>
                                        <div className="col-8">
                                            <div style={{ color: '#808080', fontSize: 20, fontWeight: 'bold' }}>{vh.name}</div>
                                            <div style={{ color: '#808080' }}>{vh.stat_desc}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        });


        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h4>Detail Produksi</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg">
                    <Container>
                        <div className="row">
                            <div className="col-4">
                                <CircularProgressbar value={this.state.transaksi.persen_prod} text={`${this.state.transaksi.persen_prod}%`}/>    
                            </div>
                            <div className="col-8">
                                <h5>{this.state.transaksi.nama_pelanggan}</h5>
                                {this.state.transaksi.tlp_pelanggan}
                                <div className="border border-top mt-1"></div>
                                <div className="font-weight-bold color-kc mt-1">{this.state.transaksi.nomor_nota}</div>
                            </div>
                        </div>

                        <h5 style={{color:this.state.color}} className="text-center mt-4 font-weight-bold">-- {this.state.notif} --</h5>
                        <div style={{backgroundColor:this.state.color, borderRadius:8, color:'#fff'}} className="text-center mt-3 p-2">
                            Estimasi Selesai
                            <h5 className="font-weight-bold">{this.state.estimasiSelesai}</h5>
                        </div>
                                    
                            
                        <div className="mb-5">
                            {detailItemTx}
                        </div>
                    </Container>


                </div>
            </div>
        );
    }
}



export default DetailProduksi;
