import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import '../../../custom-input-file.css';
import Spinner from './../../Spinner'
import invoke from 'react-native-webview-invoke/browser'
import {Container, Button} from 'react-bootstrap'
import history from './../../history';
import {ucWords, formatMoney} from './../../custom-func'
import { IoCloseCircle } from "react-icons/io5";
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class UploadFoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            keywords:'',
            offset:0,
            nxt:1,
            transaksi: []
        };
        this.query = new URLSearchParams(this.props.location.search);

        this.getDetailItem = this.getDetailItem.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.onImageDel = this.onImageDel.bind(this);
        this.delImage = this.delImage.bind(this);
        this.cetakNota = this.cetakNota.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            this.getDetailItem()
        }
    }

    onImageChange(event) {
        const target = event.target;
        const name = target.name;
        console.log(name)
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.uploadImage(name,img)
            // swalKC.fire({
            //     text: 'Simpan foto ini?',
            //     imageUrl: URL.createObjectURL(img),
            //     imageWidth: '100%',
            //     imageHeight: '500px',
            //     imageAlt: 'Custom image',
            //     showCancelButton: true,
            //     cancelButtonText: 'Batal',
            //     confirmButtonText: 'Ya, Simpan!'
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //         this.uploadImage(name,img)
            //     }
            //   })

        }
    }

    uploadImage(name,img){

        this.setState({disabled:true})
        let formData = new FormData();
        formData.append("kd_detail_tx", name);
        formData.append("img", img);
        formData.append("kode_user", localStorage.getItem('kode_user'));
        formData.append("api_token", localStorage.getItem('token'));
        formData.append("schema", localStorage.getItem('schema'));

        fetch(REACT_APP_API_URL+'transaksi-upload-image', {method: "POST", body: formData})
        .then((responseJson) => {
            console.log(responseJson)
            this.getDetailItem()
            this.setState({disabled:false})
        })
        .catch((error) => {
            console.error(error);
            this.getDetailItem()
            this.setState({disabled:false})
        });
    }


    onImageDel(namaImg, kd_det_tx){
        swalKC.fire({
            title: 'Hapus foto?',
            text: "Foto tidak bisa di kembalikan",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.delImage(namaImg, kd_det_tx)
            }
          })
    }
    
    delImage(namaImg, kd_det_tx){

        this.setState({disabled:true})
        fetch(REACT_APP_API_URL+'transaksi-del-image', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                kd_detail_tx: kd_det_tx,
                namaImg: namaImg,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.getDetailItem()
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
            this.getDetailItem()
        });
    }


    cetakNota(){
        this.setState({disabled:true})
        history.replace('/cetak-nota?kdTx='+this.query.get('kdTx')+'&ambil=0')
    }

    getDetailItem(){

        fetch(REACT_APP_API_URL+'get-item-transaksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdTx: this.query.get('kdTx'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.setState({
                transaksi: responseJson.data
            })
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    render() {  

        let ts = this;
        const pilihFile = invoke.bind('pilihFile')
        const uploadFile = async function(detTrx) {
            var url = REACT_APP_API_URL+'transaksi-upload-image';
            var kode_user = localStorage.getItem('kode_user');
            var token = localStorage.getItem('token');
            var schema = localStorage.getItem('schema');
            await pilihFile(url, detTrx, kode_user, token, schema)
        }

        invoke.define('statusGambar', statusGambar)
        function statusGambar(status) {
            ts.getDetailItem()
        }


        const detailItemTx = this.state.transaksi.map((val, id) => {
            return (
                <div key={val.kode_detail_tx} className="border mb-3">

                    <div className="w-100 border-bottom">
                        <div className="row m-0">
                            <div className="col-10 p-0">
                                <div className="font-weight-bold  p-1 pl-2">{ucWords(val.nama_item)}</div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-6 p-0">
                                <div className="p-1 pl-2">
                                    <div>{formatMoney(val.total_item)} {val.nama_satuan === 'KG' ? 'KG' : 'PCS'}</div>
                                    { val.nama_satuan === 'M' ?
                                    <div>{formatMoney(val.panjang_item)} {val.nama_satuan} x {formatMoney(val.panjang_item)} {val.nama_satuan} = {formatMoney(val.total_luas)} {val.nama_satuan}</div>
                                    :null }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-bottom w-100">
                        {/* <input type="file" name={val.kode_detail_tx} id={'file'+id} onChange={this.onImageChange} accept="image/*"  className="inputfile" />
                        <label for={'file'+id}>Upload Foto</label> */}

                        {/* <Button className="btn-light w-100 btn-kc color-kc text-white br-0" block onClick={ () => uploadFile(val.kode_detail_tx) } > <h6 className="m-0 p-0">Upload Foto</h6> </Button> */}
                        

                        <div style={{overflowX:'auto', display:'flex'}}>
                        {   
                            val.gambar != null ?
                            JSON.parse(val.gambar).map((v, i) => {
                                return(
                                    <div style={{flex: '0 0 25%', height:120}}  key={i} className="p-1" >
                                        <img src={"https://kc-imgs.oss-ap-southeast-5.aliyuncs.com/kc/transaksi/"+localStorage.getItem('schema')+"/"+v+"?x-oss-process=image/resize,m_fixed,w_100,h_80"} />
                                        <div className="pr-1 bg-danger text-center w-100" style={{color:'#fff'}} onClick={() => this.onImageDel(v, val.kode_detail_tx)}><p>Hapus <IoCloseCircle /></p></div>
                                    </div>
                                )
                            })
                            : null
                        }
                        </div>
                    </div>

                </div>
            );
        });


        return (
            this.state.disabled == true ?
            <Spinner /> 
            :
            <div>
                {/* <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1">
                            </div>
                            <div className="col-11">
                                <h4>Upload Foto Item</h4>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="pt-reg pb-reg">
                    <Container>
                        {detailItemTx}
                    </Container>
                </div>

                <div className="fix-bottom">

                        <Container>
                            <div className="row">
                                <div className="col-12  p-1">
                                    <Button className="btn-light w-100 btn-kc-outline text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => this.cetakNota() } ><span className="color-kc">Cetak / Kirim Nota </span></Button>
                                </div>
                            </div>
                        </Container>
                    </div>
            </div>
        );
    }
}



export default UploadFoto;
