import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {formatMoney} from '../custom-func'
import {Container} from 'react-bootstrap'
import { IoReload, IoStorefront } from "react-icons/io5";
import history from './../history';
import invoke from 'react-native-webview-invoke/browser'

import transaksi from '../../img/transaksi.png';
import langanan from '../../img/langanan.png';
import search from '../../img/search.png';
import rak from '../../img/rak.png';
import laporan_transaksi from '../../img/laporan-transaksi.png';
import produksi from '../../img/produksi.png';
import customer from '../../img/customer.png';
import dashboard_img from '../../img/dashboard.png';
import printer from '../../img/printer.png';
import biaya from '../../img/biaya.png';
import sign_out from '../../img/sign-out.png';

const {
    REACT_APP_API_URL,
} = process.env;

const goToPrint = invoke.bind('goToPrint')
const print = async function() {
    await goToPrint()
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            kasir: 0,
            workshop: 0,
            admin: 0,
            hapus_trx: 0,
            rekap: 0,
            level: 0,
            biaya: 0,
            user: '',
            outlet: '',
            omset: '',
            pembayaran: '',
            tgl: '',
        };
        this.logout = this.logout.bind(this);
        this.getDataUser = this.getDataUser.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            this.getDataUser()
        }
    }

    getDataUser(){
        fetch(REACT_APP_API_URL+'dashboard', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('ts : ' + JSON.stringify(responseJson))
            console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    kasir: responseJson.data.kasir,
                    workshop: responseJson.data.workshop,
                    admin: responseJson.data.admin,
                    hapus_trx: responseJson.data.hapus_trx,
                    biaya: responseJson.data.biaya,
                    rekap: responseJson.data.rekap,
                    user: responseJson.data.user,
                    level: responseJson.data.level,
                    outlet: responseJson.data.outlet,
                    omset: responseJson.data.omset,
                    pembayaran: responseJson.data.pembayaran,
                    tgl: responseJson.data.tgl,
                })

                localStorage.setItem('role', JSON.stringify(responseJson.data.role));
                localStorage.setItem('level', responseJson.data.level);
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }
    
    logout(){
        localStorage.clear();
        history.replace('/')
    }


    render() {       
        const ts = this
        const konekBluetooth = invoke.bind('konekBluetooth')

        const konekB = async function() {
            await konekBluetooth()
        }

        
        return (
            <div className="bg-color">
                <div className="fixed-position">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-10">
                                <h5>{this.state.outlet}</h5>
                            </div>
                            <div className="col-1 color-kc" onClick={() => { this.props.history.push('pilih-store') }}>
                                {/* <h5><IoStorefront /></h5> */}
                            </div>
                        </div>
                    </div>

                    <Container className="back-color pt-3">
                        <p className="text-grey">Selamat Datang, {this.state.user}</p>

                        {
                            this.state.kasir === 1 ?
                                <div className="bg-kc p-2 text-white rounded">
                                    <span className="font-10">Data Terupdate : {this.state.tgl}</span>
                                    <div className="row p-0 m-0">
                                        <div className="col-3 p-0 m-0">
                                            <span className="font-weight-bold">Kas Laci</span>
                                            <br/><span className="font-weight-bold">Omset</span>
                                        </div>
                                        <div className="col-7 p-0 m-0">
                                            <span className="font-weight-bold">{formatMoney(this.state.pembayaran)}</span>
                                            <br/><span className="font-weight-bold">{formatMoney(this.state.omset)}</span>
                                        </div>
                                        <div className="col-2 p-0 m-0">
                                            <h2 className="text-white" onClick={this.getDataUser}><IoReload /></h2>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </Container>
                </div>

                <div className="pt-header">
                    <Container className="w-90 justify-content-center">
                        <div className="row justify-content-center">
                            {
                                this.state.kasir === 1 ?
                                    <div className="col-4 pt-3 pb-3" onClick={() => { this.props.history.push('pilih-cust?ke=tx&name=transaksi&cust=1') }}>
                                        <div className="text-dashboard">
                                            <img src={transaksi} className="img-dashboard" />
                                            Transaksi Reguler
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.kasir === 1 ?
                                    this.state.level >= 2 ?
                                        <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('pilih-cust?ke=tx_voucher&name=pembelian+voucher&cust=1') }>
                                            <div className="text-dashboard">
                                                <img src={langanan} className="img-dashboard" />
                                                Pembelian Voucher
                                            </div>
                                        </div>
                                        : null
                                    : null
                            }

                            {
                                this.state.kasir === 1 ?
                                    <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('pilih-cust?ke=cari_tx&name=cari+pesanan&scan=1') }>
                                        <div className="text-dashboard">
                                            <img src={search} className="img-dashboard" />
                                            Cari Pesanan
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.kasir === 1 ?
                                    this.state.level >= 3 ?
                                        <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('list-produksi?ke=masuk_rak&name=Pilih+Item+Pelanggan&scan=1') }>
                                            <div className="text-dashboard">
                                                <img src={rak} className="img-dashboard" />
                                                Masuk Rak Outlet
                                            </div>
                                        </div>
                                        : null
                                    : null
                            }

                            {
                                this.state.kasir === 1 ?
                                    this.state.rekap === 1 ?
                                        <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('rekap-harian')}>
                                            <div className="text-dashboard">
                                                <img src={laporan_transaksi} className="img-dashboard" />
                                                Rekap Kas Harian
                                            </div>
                                        </div>
                                        : null
                                    : null
                            }

                            {
                                this.state.workshop === 1 ?
                                    this.state.level >= 3 ?
                                        <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('list-produksi?ke=produksi&name=Pilih+Item+Pelanggan&scan=1') }>
                                            <div className="text-dashboard">
                                                <img src={produksi} className="img-dashboard" />
                                                Produksi
                                            </div>
                                        </div>
                                        : null
                                    : null
                            }

                            {/* {
                                this.state.kasir === 1 ?
                                    <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('pilih-cust?ke=data_cust&name=cari+pelanggan')}>
                                        <div className="text-dashboard">
                                            <img src={customer} className="img-dashboard" />
                                            Data Pelanggan
                                        </div>
                                    </div>
                                    : null
                            } */}

                            {
                                this.state.kasir === 1 ?
                                    this.state.biaya === 1 ?
                                        <div className="col-4 pt-3 pb-3" onClick={() => this.props.history.push('pengeluaran')}>
                                            <div className="text-dashboard">
                                                <img src={biaya} className="img-dashboard" />
                                                Biaya
                                            </div>
                                        </div>
                                    : null
                                : null
                            }

                            {
                                this.state.admin === 1 ?
                                    <div className="col-4 pt-3 pb-3" onClick={() => window.open('https://owner.mrklinpos.com')}>
                                        <div className="text-dashboard">
                                            <img src={dashboard_img} className="img-dashboard" />
                                            Admin Dashboard
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.kasir === 1 ?
                                    <div className="col-4 pt-3 pb-3" onClick={() => konekB()}>
                                        <div className="text-dashboard">
                                            <img src={printer} className="img-dashboard" />
                                            Pengaturan Printer
                                        </div>
                                    </div>
                                    : null
                            }

                            <div className="col-4 pt-3 pb-3" onClick={() => this.logout()}>
                                <div className="text-dashboard">
                                    <img src={sign_out} className="img-dashboard" />
                                Keluar
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}



export default Dashboard;
