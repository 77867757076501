export const ucWords = (str)=>{
    return str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    })
}

export const formatAngka = (number)=>{
    return new Intl.NumberFormat(['ban', 'id']).format(number)
}

export const formatMoney = (angka=0, prefix = '')=>{
    // return new Intl.NumberFormat('id').format(number)
    // return number;

    var number_string = angka.toString().replace(/[^,\d]/g, ''),
    split   		= number_string.split(','),
    sisa     		= split[0].length % 3,
    rupiah     		= split[0].substr(0, sisa),
    ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    var separator = '';
    if(ribuan){
        separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah
}


export const formatDateDFY = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();

    let monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ];

    let monthName = monthNames[month];
    return `${day} ${monthName}, ${year}`;
}

export const formatDateDFYHI = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = '' + d.getFullYear(),
        jam = '' + d.getHours(),
        menit = d.getMinutes();

    let monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ];

    let monthName = monthNames[month];
    return `${day} ${monthName} ${year} ${jam}:${menit}`;
}
