import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap'

import InputGroup from 'react-bootstrap/InputGroup'
import history from './../history';
import { IoArrowBack, IoQrCodeOutline } from "react-icons/io5";
import { AiOutlineQrcode } from "react-icons/ai";
import {ucWords} from './../custom-func'
import { RiSearchEyeLine } from "react-icons/ri";
import { AiFillPlusCircle } from 'react-icons/ai';
import Swal from 'sweetalert2'

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})

const {
    REACT_APP_API_URL,
} = process.env;

class PilihCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            keywords:'',
            offset:0,
            nxt:1,
            pelanggan: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.cariDataCustomer = this.cariDataCustomer.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.daftarCustomer = this.daftarCustomer.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

            this.cariDataCustomer(0)
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    cariDataCustomer(reset){
        this.setState({
            pelanggan: []
        })
        let offset = this.state.offset;
        if(reset === 1){
            offset = 0;
        }

        fetch(REACT_APP_API_URL+'get-cust', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                keyword: this.state.keywords,
                offset: offset,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.status)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    offset: responseJson.next_offset
                })

                var dataCust = [];
                if(reset === 0){
                    dataCust = this.state.pelanggan;
                }
                
                var nxt = 0;
                if(responseJson.data.length > 0){
                    nxt = 1;
                }

                const dtCust = responseJson.data;
                dtCust.forEach((val) => {
                    dataCust.push(val)
                });
                this.setState({
                    pelanggan: dataCust,
                    nxt: nxt
                })
                
            }

            
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }

    goToNext(kdCust){
        // isinya sama buat customer
        let ke = this.query.get('ke');
        if(ke === 'tx'){
            this.props.history.push('tambah-transaksi?kdCust='+kdCust)
        }

        if(ke === 'cari_tx'){
            this.props.history.push('cari-pesanan?kdCust='+kdCust)
        }
        

        if(ke === 'tx_voucher'){
            this.props.history.push('beli-voucher?kdCust='+kdCust)
        }
        
    }

    daftarCustomer(){
        let ke = this.query.get('ke');
        
        this.props.history.push('daftar-pelanggan?ke='+ke)
    }

    render() {  
        const dataPelanggan = this.state.pelanggan.map((val, id) => {
            return (
                <div key={val.kode_pelanggan+Math.random()} className="text-grey border-bottom" onClick={() => this.goToNext(val.kode_pelanggan)}>
                    <div className="p-1 m-1">
                        <div>{ucWords(val.nama_pelanggan)}</div>
                        <div className="font-10">{val.tlp_pelanggan}</div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h4>{ucWords(this.query.get('name'))}</h4>
                            </div>
                            {
                                this.query.get('scan') == 1 ?
                                    <div className="col-2">
                                        <h5><IoQrCodeOutline /></h5>
                                    </div>
                                : null
                            }
                        </div>
                    </div>

                    <Container>
                        <InputGroup className="mt-3 mb-3">
                            <Form.Control
                            type="text"
                            placeholder="Cari Nama Pelanggan"
                            className="br0 form-input"
                            name="keywords" 
                            value={this.state.keywords}
                            onChange={this.handleInputChange}
                            />
                            <h3 className="ic-fl-rg text-black-50" onClick={() => this.cariDataCustomer(1)}>
                                <RiSearchEyeLine/>
                            </h3>
                        </InputGroup>
                    </Container>

                </div>

                <div className="pt-cust">
                    <Container>
                        {dataPelanggan}
                        {
                            this.state.nxt == 1 ?
                            <Button className="btn-light w-100 mt-3 mb-3"  onClick={() => this.cariDataCustomer(0)}>Lihat Data Lainnya</Button>
                            :null
                        }

                        {
                            this.query.get('cust') == 1 ?
                                <h1 style={{position:'fixed', right:20, bottom:10}} className="color-kc"  onClick={() => this.daftarCustomer()}><AiFillPlusCircle /></h1>
                            : null    
                        }
                    </Container>
                </div>
            </div>
        );
    }
}



export default PilihCustomer;
