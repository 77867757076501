import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import history from './../history';
import logo from '../../img/mrklin-logo.png';
import Swal from 'sweetalert2';

const {
    REACT_APP_API_URL,
  } = process.env;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            email: '',
            password: '',
        };
        this.showPass = this.showPass.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token')){
            history.replace('/dashboard')
        }
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }


    showPass(){
        this.setState({ hidden: !this.state.hidden })
    }

    login(){
        this.setState({ disabled: true })
        fetch(REACT_APP_API_URL+'login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('ts : ' + JSON.stringify(responseJson))
            this.setState({ disabled: false })
            if(responseJson.status){
                localStorage.setItem('kode_user', responseJson.data.kode_user);
                localStorage.setItem('schema', responseJson.data.schema);
                localStorage.setItem('token', responseJson.data.token);

                history.replace('/dashboard')
            }else{
                Swal.fire({
                    title: 'Login Gagal!',
                    text: 'User atau password anda salah',
                    icon: 'warning',
                    showCancelButton: false,
                    cancelButtonText: 'Tutup'
                })
            }
        })
        .catch((error) => {
            this.setState({ disabled: false })
            console.error(error);
            this.setState({ loading: 0 })
        });

    }


    render() {        
        return (
            <Container>

                <Form className="pt-40 form-login">
                    <div className="text-center mb-3">
                        <img src={logo} className="w-75" />
                    </div>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control type="email" placeholder="Email" className="br0 form-input" value={this.state.email} name="email" onChange={this.handleInputChange} />
                    </Form.Group>
                    
                    <InputGroup className="mb-3">
                        <Form.Control
                        type={this.state.hidden ? 'password' : 'text'}
                        placeholder="Password"
                        className="br0 form-input form-password"
                        name="password" 
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        />
                        <h3 className="ic-fl-rg text-black-50" onClick={this.showPass} >
                            {this.state.hidden ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </h3>
                    </InputGroup>

                    <Button className="btn-light btn-kc br0 mb-2 text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={this.login.bind(this)} >Log In</Button>
                    {/* <Button className="btn-light btn-kc br0 mb-2 text-white" block onClick={ 1() => history. push('/dashboard')}>Log In</Button> */}
                    <p className="text-center text-black-50 font-10">
                        Belum punya akun? <span className="color-kc font-weight-bold">Daftar Sekarang!</span>
                    </p>
                </Form>
            </Container>
        );
    }
}


export default Login;
