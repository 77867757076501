import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap'

import InputGroup from 'react-bootstrap/InputGroup'
import history from './../history';
import { IoArrowBack, IoQrCodeOutline } from "react-icons/io5";
import {ucWords} from './../custom-func'
import { RiSearchEyeLine } from "react-icons/ri";
import { CircularProgressbar } from 'react-circular-progressbar';
import Swal from 'sweetalert2'

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class ListProduksi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            keywords:'',
            offset:0,
            nxt:1,
            produksi: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.cariListProduksi = this.cariListProduksi.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goToNext = this.goToNext.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            let ke = this.query.get('ke');  
            const level = localStorage.getItem('level');
            const role = JSON.parse(localStorage.getItem('role'));
            if(ke != 'masuk_rak'){
                
                if(level < 3 || role.pkj != 1 ){
                    swalKC.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Maaf anda tidak memiliki akses di fitur ini',
                        showCancelButton: false,
                        confirmButtonText: 'Kembali'
                      }).then((result) => {
                        history.goBack()
                      })
                }
            }else{
                if(role.ksr != 1 ){
                    swalKC.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Maaf anda tidak memiliki akses di fitur ini',
                        showCancelButton: false,
                        confirmButtonText: 'Kembali'
                      }).then((result) => {
                        history.goBack()
                      })
                }
            }

            
            this.cariListProduksi(0)
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    cariListProduksi(reset){

        let offset = this.state.offset;
        if(reset === 1){
            offset = 0;
        }

        fetch(REACT_APP_API_URL+'get-list-produksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                keyword: this.state.keywords,
                offset: offset,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    offset: responseJson.next_offset
                })

                var dataCust = [];
                if(reset === 0){
                    dataCust = this.state.produksi;
                }
                
                var nxt = 0;
                if(responseJson.data.length > 0){
                    nxt = 1;
                }

                const dtCust = responseJson.data;
                dtCust.forEach((val) => {
                    dataCust.push(val)
                });
                this.setState({
                    produksi: dataCust,
                    nxt: nxt
                })
                
            }else{

                this.setState({
                    produksi: [],
                    nxt: 0
                })
            }

            
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }

    goToNext(kdTrx){
        // isinya sama buat customer
        let ke = this.query.get('ke');
        this.props.history.push('detail-produksi?ke='+ke+'&kdTrx='+kdTrx)
    }
    
    render() {  
        const lstProduksi = this.state.produksi.map((val, id) => {
            return (
                <div key={val.kode_transaksi+Math.random()} className="border mt-2" onClick={() => this.goToNext(val.kode_transaksi)}>
                    <div className="p-1 m-1">

                        <div className="row pt-1">
                            <div className="col-9">
                                <div className="font-weight-bold">{val.nama_pelanggan}</div>
                                <div className="font-weight-bold color-kc">{val.nomor_nota}</div>
                                <span className={val.color}>{val.status}</span>
                            </div>
                            <div className="col-3">
                                <CircularProgressbar value={val.persen_prod} text={`${val.persen_prod}%`}/>    
                            </div>
                        </div>
                        <div className=" border-top mt-2"></div>
                        <div className="row pt-1">
                            <div className="col-6">
                                <div className="text-grey">Tanggal Terima</div>
                            </div>
                            <div className="col-6">
                                <div className="text-grey text-right">{val.tanggal_transaksi}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="text-grey">Tanggal Selesai</div>
                            </div>
                            <div className="col-6">
                                <div className="text-grey text-right">{val.selesai}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h4>{ucWords(this.query.get('name'))}</h4>
                            </div>
                            {
                                this.query.get('scan') == 1 ?
                                    <div className="col-2">
                                        <h5><IoQrCodeOutline /></h5>
                                    </div>
                                : null
                            }
                        </div>
                    </div>

                    <Container>
                        <InputGroup className="mt-3 mb-3">
                            <Form.Control
                            type="text"
                            placeholder="No Transaksi / Nama Pelanggan"
                            className="br0 form-input"
                            name="keywords" 
                            value={this.state.keywords}
                            onChange={this.handleInputChange}
                            />
                            <h3 className="ic-fl-rg text-black-50" onClick={() => this.cariListProduksi(1)}>
                                <RiSearchEyeLine/>
                            </h3>
                        </InputGroup>
                    </Container>

                </div>

                <div className="pt-cust">
                    <Container>
                        {lstProduksi}
                        {
                            this.state.nxt == 1 ?
                            <Button className="btn-light w-100 mt-3 mb-3"  onClick={() => this.cariListProduksi(0)}>Lihat Data Lainnya</Button>
                            :null
                        }
                    </Container>
                </div>
            </div>
        );
    }
}



export default ListProduksi;
